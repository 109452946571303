import React from 'react'
import { Input, Button, Dropdown, Form, Segment, Header, Divider, Icon } from 'semantic-ui-react'
import { post } from 'axios'

const ConfigForm = (props) => (
  <Form>
    <Segment className="flex">
      <Dropdown
        placeholder={props.options[props.intIndex].text}
        fluid
        search
        options={props.options}
        selection
        className="mr3"
        onChange={(event, {value}) => props.onChangeIntegration(value)}
      />
      <Button icon onClick={() => props.createIntegration()}>
        <Icon name="plus"/>
      </Button>
    </Segment>
    <Segment>
      <Header as="h4" textAlign="left" color="grey">Identificacao</Header>
      <Divider />
      <Input 
        fluid
        label="Nome"
        className="mv2"
        value={props.current.label}
        onChange={(event, {value}) => props.onChangeName(props.intIndex, value)}
      />
      <Input 
        disabled
        fluid
        label="Id"
        className="mv2"
        value={props.current.id}
      />
      <Input
        disabled
        fluid
        label="Webhook"
        className="mv2"
        value={props.current.id ? 
          `${window.location.protocol}//${window.location.hostname}/api/conversion/${props.current.id}` 
          : ''
        }
      />
    </Segment>
    {/* <Segment>
      <Header as="h4" textAlign="left" color="grey">Informações do RD Station</Header>
      <Divider />
      <Input 
        label="Client_ID"
        fluid
        value={props.clientId}
        onChange={(e, {value}) => props.onUpdateClientId(value)}
        placeholder="2cdt4yoytbvockc51cowgs4gsss84ow4s" 
      />
      <Input 
        label="Client_secrete"
        fluid
        value={props.clientId}
        onChange={(e, {value}) => props.onUpdateClientId(value)}
        placeholder="2cdt4yoytbvockc51cowgs4gsss84ow4s" 
      />
    </Segment> */}
    <Segment>
      <Header as="h4" textAlign="left" color="grey">Informações do Data Box</Header>
      <Divider />
      <Input 
        label="DataBox Push Token"
        fluid
        className="mv2"
        value={props.current.db_push_token}
        onChange={(e, {value}) => props.onUpdateToken(props.intIndex, value)}
        placeholder="2cdt4yoytbvockc51cowgs4gsss84ow4s" 
      />
    </Segment>
    <Segment className="tc">
      <Button 
        positive
        onClick={() => props.onSubmit(props.current)}
      >{props.submitText}</Button> 
      {props.showDelete ?
        <Button
          onClick={() => props.onDelete(props.current.id)}
          negative
        >Excluir Integração</Button>
      : null}
    </Segment>
  </Form>  
)

export default ConfigForm