import localAuth from '../localAuth'
import { 
  receiveAuthentication,
  authenticationFail
} from '../../actions/api'
import { loginError } from '../../actions'
import store from '../../store'

import authenticate from './authenticate'
import createIntegration from './create'
import listIntegrations from './list'
import updateIntegration from './update'
import deleteIntegration from './delete'

const endpoint = process.env.REACT_APP_API_ENDPOINT

class Integrations {
  async authenticate (tokenId) {
    this.tokenId = tokenId

    const response = await authenticate(endpoint, tokenId);
    let action = loginError

    if (response.status === 200) action = receiveAuthentication

    store.dispatch(action(response.data));
    return response.data
  }

  async authenticateRequest () {
    const storeState = store.getState()
    const { api } = storeState
    let { accessToken } = api

    if (!accessToken) {
      const session = localAuth.getSession()
      accessToken = await this.authenticate(session.id_token)
      accessToken = accessToken.access_token
    }

    return accessToken
  }

  async create (data) {
    const accessToken = await this.authenticateRequest()
    const headers = {
      "x-access-token": accessToken.token
    }

    return await createIntegration(endpoint, data, headers)
  }

  async list (params) {
    const accessToken = await this.authenticateRequest()

    params = {
      headers: {
        "x-access-token": accessToken.token
      },
      ...params
    }

    return await listIntegrations(endpoint, params)
  }

  async update (id, data) {
    delete data.id
    
    const accessToken = await this.authenticateRequest()
    const headers = {
      "x-access-token": accessToken.token
    }

    return await updateIntegration(endpoint, id, data, headers)
  }

  async delete (id) {
    const accessToken = await this.authenticateRequest()
    const headers = {
      "x-access-token": accessToken.token
    }

    return await deleteIntegration(endpoint, id, headers)
  }
}

const integracoes = new Integrations()

export default integracoes