import React from 'react'

const date = new Date()
const year = date.getFullYear()

const CopyrightNotice = () => (
  <small className="db w-100 f6 tc mv2">
    Copyright© {year} Avellar Media.<br/>
    Todos os direitos reservados.
  </small>
)

export default CopyrightNotice