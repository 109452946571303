import React, { PureComponent } from 'react'
import ConfigForm from '../components/ConfigForm'
import integrations from '../modules/integration'
import { toast } from 'react-toastify'
import shortid from 'shortid'
import { SSL_OP_DONT_INSERT_EMPTY_FRAGMENTS } from 'constants';

const initialIntegrations = [
  {
    key: "", 
    id: "",
    label: "",
    slug: "",
    db_push_token: ""
  }
]

const initialOptions = [
  {
    key: initialIntegrations[0].id,
    value: initialIntegrations[0].id,
    text: initialIntegrations[0].label
  }
]

function integrationsToOptions (integrations) {
  return integrations.map((integration) => {
    return {
      key: integration.key,
      value: integration.id,
      text: integration.label
    }
  })
}

class ConfigFormContainer extends PureComponent {

    state = {
        intId: initialIntegrations[0].id,
        intIndex: 0,
        options: initialOptions,
        integrations: initialIntegrations,
        current: {
          ...initialIntegrations[0]
        }
    }

    notify = (message, type = 'default') => toast(message, {type})

    componentDidMount(){
      this.getIntegrations()
    }

    onNewIntegration = (integration) => {
        const label = integration ? integration.label : 'Nova Integração'
        const key = shortid.generate()

        const newIntegration = {
          key,
          label,
          id: '',
          db_push_token: ''
        }

        const integrations = [
            ...this.state.integrations,
            {...newIntegration}
        ]
        
        const options = [
          ...this.state.options,
          {
            key,
            value: key,
            text: label
          }
        ]

        this.setState({
            integrations,
            options,
            isNew: !this.state.isNew,
            current: newIntegration
        },
        () => this.onChangeIntegration(key))
    }

    onIntegrationCreated = (integration) => {
      const integrations = this.state.integrations.map((item) => {
        let ret = { ...item }
        
        if (item.id === integration.id) {
          ret = {
            ...ret,
            ...integration
          }
        }

        return ret
      })      

      this.setState({
        integrations,
        isNew: false,
        current: integration
      })
    }

    onChangeIntegration = (intId, callback = () => true) => {
      const intIndex = this.state.options.findIndex(({key}) => key === intId)
      
      const integration = this.state.integrations[intIndex]
      const current = {...integration}
      
      this.setState({
          intId: intId,
          intIndex,
          current
      }, (param) => callback(param))
    }

    onDeleteIntegration = (id) => {
      const index = this.state.options.findIndex(({key}) => key === id);
      const previousIndex = (index) ? index - 1 : 0
      const intId = this.state.integrations[previousIndex].id

      const integrations = this.state.integrations.filter((el, i) => i != index)
      const options = this.state.options.filter((el, i) => i !== index)

      if (!integrations.length) {
        this.resetIntegrations()
        return
      }

      this.onChangeIntegration(intId, () => {
        this.setState({
          integrations,
          options,
        })
      })
    }

    createIntegration = () => {
      integrations.create(this.state.current).then((response) => {
        const integration = response.data
        this.onIntegrationCreated(integration)
        this.notify('Integração criada com sucesso!', toast.TYPE.SUCCESS)
      }).catch((res) => {
        this.notify('Falha ao criar integração!', toast.TYPE.ERROR)
      })
    }

    updateIntegration = (integration) => {
      const data = {...integration}
      const id = data.id
      delete(data.id, data.key)

      integrations.update(id, data).then((res) => {
        this.onChangeIntegration(id)
        this.notify('Integração atualizada com sucesso!', toast.TYPE.SUCCESS)
      }).catch((res) => {
        this.notify('Falha ao atualizar integração!', toast.TYPE.ERROR)
      })
    }

    deleteIntegration = (id) => {
      integrations.delete(id).then((res) => {
        this.onDeleteIntegration(id)
        this.notify('Integração removida com sucesso!', toast.TYPE.SUCCESS)
      }).catch((res) => {
        console.log('why', res)
        this.notify('Falha ao remover integração!', toast.TYPE.ERROR)
      })
    }

    onUpdateToken = (intIndex, newToken) => {
      let integrations = [ ...this.state.integrations ]
      let options = [ ...this.state.options ]
      integrations[intIndex].db_push_token = newToken

      this.setState({
        options,
        integrations,
        current: integrations[intIndex]
      })
    }

    onChangeName = (intIndex, newName) => {
        let integrations = [ ...this.state.integrations ]
        let options = [ ...this.state.options ]

        options[intIndex].text = integrations[intIndex].label = newName
        
        this.setState({
            options,
            integrations,
            current: integrations[intIndex]
        })
    }


    getIntegrations = () => {
      integrations.list().then((integrations) => {
        let integrationList = integrations.map((integration) => {
          return {
            ...integration,
            key: integration.id
          }
        })

        if (!integrationList.length) {
          this.resetIntegrations()
          return
        }

        this.setState({
          integrations: integrationList,
          intId: integrationList[0].key,
          options: integrationsToOptions(integrationList),
          current: integrationList[0]
        })
      }).catch((res) => {
        this.notify('Não foi possível se comunicar com nossos servidores!', toast.TYPE.ERROR)
      })
    }

    resetIntegrations = () => {
      this.setState({
        integrations: initialIntegrations,
        intId: initialIntegrations[0].key,
        options: integrationsToOptions(initialIntegrations),
        current: initialIntegrations[0]
      })
    }

    render () {
        return (
            <ConfigForm 
                current={this.state.current}
                push_token={this.state.push_token}
                options={this.state.options}
                intIndex={this.state.intIndex}
                createIntegration={this.onNewIntegration}
                onChangeName={this.onChangeName}
                onChangeIntegration={this.onChangeIntegration}
                onUpdateToken={this.onUpdateToken}
                onSubmit={
                  this.state.current.id?
                  this.updateIntegration
                  : this.createIntegration
                }
                onDelete={this.deleteIntegration}
                submitText={
                  this.state.current.id?
                  "Atualizar Integração"
                  : "Criar Integração"
                }
                showDelete={this.state.current.id}
            />
        )
    }   
}

export default ConfigFormContainer