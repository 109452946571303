import React, { Component} from 'react'
import GoogleLogin from 'react-google-login'
import { receiveLogin } from '../actions'
import store from '../store'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import localAuth from '../modules/localAuth'
import integration from '../modules/integration'
import { toast } from 'react-toastify'

class LoginForm extends Component {
  onSuccess (res) {
    const credentials = {
      access_token: res.accessToken,
      id_token: res.tokenId
    }

    integration.authenticate(res.tokenId).then((res) => {
      localAuth.storeSession(credentials)
      store.dispatch(receiveLogin(credentials))
      store.dispatch(push('/'))
    }).catch((res) => {
      toast('Não foi possivel se comunicar com nossos servidores!', {type: toast.TYPE.ERROR})
    })
  }

  onFailure (res) {
    toast('Usuário inválido!', {type: toast.TYPE.ERROR})
  }

  render () {
    return (
      <div className={`LoginForm flex justify-center ${this.props.className || ''}`}>
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
            buttonText="Login"
            onSuccess={this.onSuccess}
            onFailure={this.onFailure}
            cookiePolicy={'single_host_origin'}
          />
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {}
}

export default connect(mapStateToProps)(LoginForm)