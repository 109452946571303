import {
  AUTH_SUCCESS,
  AUTH_FAILURE
} from '../actions/api'

const initialState = {
  accessToken: false,
  error: false,
}

function api (state = initialState, action) {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        accessToken: action.data.access_token
      }
    case AUTH_FAILURE:
    default:
      return initialState
  }
}

export default api