import React, {Fragment} from 'react'
import AppBar from '../components/AppBar'
import ConfigForm from '../containers/ConfigForm'
import CopyrightNotice from '../components/CopyrightNotice';

const Dashboard = () => (
  <Fragment>
    <AppBar />
    <div className="w-100 mw7 pv5 center">
      <ConfigForm />
      <CopyrightNotice />
    </div>
  </Fragment>  
)

export default Dashboard