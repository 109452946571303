import lscache from 'lscache'

const expireTime = 36000

class LocalAuth {
  storeSession (credentials) {
    lscache.set('id_token', credentials.id_token, expireTime)
    lscache.set('access_token', credentials.access_token, expireTime)
  }

  getSession () {
    return {
      id_token:  lscache.get('id_token'),
      access_token: lscache.get('access_token')
    }
  }

  revokeSession () {
    lscache.remove('id_token')
    lscache.remove('access_token')
  }
}

const localAuth = new LocalAuth()

export default localAuth