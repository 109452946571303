import {get} from 'axios'

async function listIntegrations (endpoint, params = {}) {
  try {
    const response = await get(`${endpoint}/integration`, params)
    const { data } = response

    return data
  } catch (error) {
    return error
  }
}

export default listIntegrations