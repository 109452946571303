import React from 'react'
import localAuth from '../../modules/localAuth'
import store from '../../store'
import { Button } from 'semantic-ui-react'
import { receiveLogout } from '../../actions'
import { GoogleLogout } from 'react-google-login'

const LogOutButton = (props) => (
  <GoogleLogout 
    clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
    onLogoutSuccess={() => {
      localAuth.revokeSession()
      store.dispatch(receiveLogout())
    }}
    render={({onClick}) => (
      <Button 
        className="pointer"  
        negative
        onClick = {onClick}
      >
        Sair
      </Button>
    )}
  />
)

export default LogOutButton