import React from 'react'
import { GoogleLogout } from 'react-google-login'
import { Header } from 'semantic-ui-react'
import { connect } from 'react-redux'

import LogOutButton from '../LogOutButton'

const AppBar = (props) => (
  <nav className="flex justify-between items-center white bg-white ph4 pv3 shadow-4">
    <Header as="h2" className="white" style={{marginBottom: 0}}>Agente de Integração RD Station - DataBox</Header>
    {props.isAuthenticated ?
      <LogOutButton />
    : null}
  </nav>
)

function mapStateToProps (state) {
  return {
    isAuthenticated: state.auth.isAuthenticated
  }
}

export default connect(mapStateToProps)(AppBar)