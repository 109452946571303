import React, {Fragment} from 'react'
import {Segment, Header, Divider} from 'semantic-ui-react'
import LoginForm from '../containers/LoginForm'
import CopyrightNotice from '../components/CopyrightNotice';

const Login = () => (
  <Fragment>
    <div className="flex w-100 h-100 justify-center items-center">
      <Segment size="massive">
        <Header as="h3" textAlign="center">Agente de Integração RD Station - DataBox</Header>
        <Divider />
        <LoginForm className="flex-auto pv3"/>
        <Divider />
        <CopyrightNotice />
      </Segment>
    </div>
  </Fragment>
)

export default Login