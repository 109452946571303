import { post } from 'axios'

async function updateIntegration (endpoint, id, data, headers) {
  const response = await post(`${endpoint}/integration/${id}`, data, {
    headers: headers
  })

  return response
}

export default updateIntegration