import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import api from './api'
import auth from './auth'

export default (history) => combineReducers({
  router: connectRouter(history),
  auth,
  api
})