import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers'

export const history = createBrowserHistory()

const createStoreWithMiddlware = applyMiddleware(
  thunkMiddleware,
  routerMiddleware(history)
)(createStore)

const store = createStoreWithMiddlware(
  createRootReducer(history),
window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

export default store