import React from 'react'
import { Route, Redirect } from 'react-router'
import { connect } from 'react-redux'

const PrivateRoute = ({component: Component, ...props}) => (
  <Route {...props} render={(innerProps) => (
    props.isAuthenticated ?
      <Component {...innerProps} />
    : <Redirect to="/login" />
  )} />
)

function mapStateToProps (state) {
  const props = {
    isAuthenticated: state.auth.isAuthenticated
  }

  return props
}

export default connect(mapStateToProps)(PrivateRoute)