export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'

export function receiveAuthentication (data) {
  return {
    type: AUTH_SUCCESS,
    data
  }
}

export function authenticationFail () {
  return {
    type: AUTH_FAILURE
  }
}