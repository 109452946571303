import React from 'react'
import AppBar from './components/AppBar'
import Routes from './routes'
import { history } from './store'
import { connect } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import 'semantic-ui-css/semantic.min.css'
import 'tachyons/css/tachyons.min.css'
import { auth } from 'google-auth-library';


function App () {
  return (
    <ConnectedRouter history={history}>
      <div className="App">
        <Routes />
      </div>
    </ConnectedRouter>
  );
}

function mapStateToProps (state) {
  const { isAuthenticated, errorMessage } = state.auth

  return {
    isAuthenticated,
    errorMessage
  }
}

export default connect(mapStateToProps)(App)
// export default App
