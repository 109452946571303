import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import Login from '../views/Login'

const LoginRoute = (props) => (
  props.isAuthenticated ?
    <Redirect to="/" />
  : <Login />
)

function mapStateToProps (state) {
  return {
    isAuthenticated: state.auth.isAuthenticated
  }
}

export default connect(mapStateToProps)(LoginRoute)