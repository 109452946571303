import React, {Fragment} from 'react'
import { Route } from 'react-router'
import PrivateRoute from '../components/PrivateRoute'
import Dashboard from '../views/Dashboard'
import Login from './Login'

const Routes = () => (
  <Fragment>
    <PrivateRoute exact path="/" component={Dashboard} />
    <Route path="/login" component={Login} />
  </Fragment>
)

export default Routes